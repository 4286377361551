import * as React from "react";
import {useAuth} from "../../AuthUser";
import {useNavigate} from "react-router-dom";
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import {Helmet} from "react-helmet";
import Button from "react-bootstrap/Button";
import API from "../../api";
import {useEffect, useState} from "react";
import moment from "moment";

import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import LoadingDiv from "../../components/LoadingDiv";


function Lessons() {
	let auth = useAuth();
	let navigate = useNavigate();

	const [lessons, setLessons] = useState([])
	const [isLoading, setLoading] = useState(false)

	useEffect(() => {
		setLoading(true);
		API.get("admin/lessons")
			.then(response => setLessons(response.data))
			.finally(() => setLoading(false));
	}, [])

	const onRowSelect = (event) => {
		navigate(`/admin/lessons/${event.data.id}`)
	};

	const consultBodyTemplate = (lesson) => {
		if (lesson.consult === 1) {
			return 'Yes'
		}
		return 'No'
	}

	const submittedDateTemplate = (lesson) => {
		return moment(lesson.created_at).format('YYYY-MM-DD');
	}

	const userTemplate = (lesson) => {
		if (lesson.first_name) {
			return `${lesson.first_name} ${lesson.last_name}`;
		}
		return lesson.email
	}

	return (
		<>
			<Helmet>
				<title>Pending Lessons | MyGolfDNA.com</title>
				<meta name="description" content="Pending Lessons"/>
			</Helmet>
			<div className="mt-4">
					<Row>
						<Col>
							<h2>Instructor Lessons</h2>
							{isLoading ? <LoadingDiv/> :
								<div className="card">
									<DataTable
										value={lessons}
										paginator rows={50}
										rowsPerPageOptions={[10, 25, 50]}
										tableStyle={{minWidth: '50rem'}}
										selectionMode="single"
										onRowSelect={onRowSelect}
									>
										<Column header="User" body={userTemplate}/>
										<Column field="consult" header="Consult" sortable body={consultBodyTemplate}/>
										<Column field="created_at" sortable header="Submitted Date" body={submittedDateTemplate}/>
									</DataTable>
							</div>
							}
						</Col>
					</Row>
			</div>
		</>
	);
}

export default Lessons;
