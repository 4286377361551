import Nav from 'react-bootstrap/Nav';
import Button from 'react-bootstrap/Button';
import {useAuth} from "../../AuthUser";
import { useNavigate } from "react-router-dom";
import { useState } from 'react';
import Offcanvas from 'react-bootstrap/Offcanvas';

function AdminNavigation({ hideAuthButton = false }) {
	const navigate = useNavigate();
	const auth = useAuth();
	const isAdmin = auth.user.admin;

	const [show, setShow] = useState(false);

	const handleClose = () => setShow(false);
	const handleShow = () => setShow(true);

	const AdminLink = () => {
			if (isAdmin) {
				return <Button variant="outline-light" size="md" onClick={() => navigate("/signin", {replace: true})}>Sign
					in</Button>;
			}
	};

	const dropdown = () => {
		return <i className="bi bi-person-circle" style={{fontSize: "1.5rem"}}/>;
	};

	return (
		<>
			<Button variant="primary" className="d-lg-none" onClick={handleShow}>
				Menu
			</Button>
			<Offcanvas show={show} onHide={handleClose} responsive="lg">
				<Offcanvas.Header closeButton>
				</Offcanvas.Header>
				<Offcanvas.Body>
					<Nav defaultActiveKey="/admin/lessons" className="flex-column sidebar">
						<Nav.Link href="/admin/lessons">Lessons</Nav.Link>
						<Nav.Link href="/admin/videos">Video List</Nav.Link>
						<Nav.Link href="/admin/videos/create">Create Video</Nav.Link>
						<Nav.Link href="/admin/categories">Category List</Nav.Link>
						<Nav.Link href="/admin/category/create">Create Category</Nav.Link>
					</Nav>
				</Offcanvas.Body>
			</Offcanvas>
		</>
	);
}

export default AdminNavigation;
