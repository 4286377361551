import {useAuth} from "../AuthUser";
import {Button} from "react-bootstrap";

const PricingButton = ({ href }) => {
	let auth = useAuth();

	if (auth.user) {
		return <Button href={href} variant="light">Subscribe</Button>
	}
	return (<Button href={`/signup?r=${href}`} variant="light">Get Started Now</Button>)
};

export default PricingButton;

