import React from "react";
import {
	Button,
	Card,
} from "react-bootstrap";
import { toast } from "react-toastify";
import API from "../../api";

const Subscription = () => {

	const isSubmitting = false;
	const notify = (message) =>
		toast.error(message, {
			position: toast.POSITION.TOP_RIGHT,
		});

	return (
				<>
					<Card>
						<Card.Header as="h5">Manage Your Subscription</Card.Header>
						<Card.Body>
							<Card.Text>
								Please contact us at support@mygolfdna.com to cancel your subscription. When you cancel, you will continue to have premium access until the end of your billing cycle.
							</Card.Text>
									<Button className="visually-hidden" variant="danger" size="sm" disabled={isSubmitting}>
										{isSubmitting ? (
											<>
													<span
														className="spinner-border spinner-border-sm"
														role="status"
														aria-hidden={true}
													/>{" "}Canceling Subscription
											</>
										) : (
											"Cancel Subscription"
										)}
									</Button>
						</Card.Body>
					</Card>
				</>
	);
};

export default Subscription;
