import React, {useState} from "react";
import {CreditCard, PaymentForm} from "react-square-web-payments-sdk";
import {
	Container,
	Row,
	Col,
	Modal,
} from "react-bootstrap";
import api from "../../api";
import {useAuth} from "../../AuthUser";
import {useNavigate, Navigate} from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import MoneyBackImg from "../../assets/img/90day.png";


const Premier = () => {
	const [show, setShow] = useState(false);
	const handleClose = () => setShow(false);
	const handleShow = () => setShow(true);

	const { setUser, user } = useAuth();
	const navigate = useNavigate();

	if (!user) {
		return <Navigate to="/pricing" replace />;
	}
	if (user.planType === 'premier') {
		return <Navigate to="/videos" replace />;
	}

	const notify = (message) =>
		toast.error(message, {
			position: toast.POSITION.TOP_RIGHT,
		});

	return (
		<Container>
			<Row>
				<Col sm={12} md={{ span: 6, offset: 3 }} lg={{ span: 6, offset: 3 }} xl={{ span: 6, offset: 3 }}>
					<Row className="align-items-center">
					<Col>
						<div className="d-grid gap-2 mt-4 mb-4">
							<h2 className="text-secondary monument-font">DNA PREMIER</h2>
							<ul className="list-unstyled">
								<li><i className="bi bi-check-circle text-success"/> 2 virtual lesson per month</li>
								<li><i className="bi bi-check-circle text-success"/> Live DNA consult at sign up</li>
								<li><i className="bi bi-check-circle text-success"/> Access to entire video library</li>
								<li><i className="bi bi-check-circle text-success"/> Lag stick training aid</li>
								<li><i className="bi bi-check-circle text-success"/> Access to pocket pro</li>
							</ul>
							<div className="d-flex align-items-center gap-1">
								<div className="h1">
									$67
								</div>
								<small className="">/month</small>
							</div>

						</div>
					</Col>
					<Col className="">
						<img
							src={MoneyBackImg}
							width={200}
							alt="moneyback"
							/>
					</Col>
					</Row>
					<h5 className="mb-3">Your Billing Details</h5>
					<PaymentForm
						applicationId="sq0idp--OAER2pSUlUDNqUV8uKXFA"
						cardTokenizeResponseReceived={async (token, verifiedBuyer) => {
							handleShow();
							await api.post("/pay/create", {
								sourceId: token.token,
								planType: "premier",
								}).then(resp => {
									setUser(resp.data);
									handleClose();
									navigate("/thankyou", { replace: true });
							}).catch(err => {
								handleClose();
								notify(err.response.data.message);
							})
						}}
						//locationId="L36AG5ZKTWKGM"
						locationId="4F2E7F3QP3YD5"
					>
						<CreditCard
							buttonProps={{
								css: {
									backgroundColor: "#157729",
									fontSize: "14px",
									color: "#fff",
									"&:hover": {
										backgroundColor: "#1f8c44",
									},
								},
							}}
						/>
					</PaymentForm>
					<div className="mt-4 text-black-50">
						<p className="small">
							Your mygolfdna.com Premier Membership will start when you click "Pay"
</p>
						<p className="small">
							This is an auto-renewing payment plan. By clicking the button "Pay", you acknowledge that you are authorizing MyGolfDNA to charge your credit/debit card today for the Total Price listed above and $67.00/month thereafter until you cancel this subscription.
</p>
						<p className="small">
							A link to cancel can be found on your Account page. Your recurring payments will be immediately canceled, but you will maintain your current access until the end of the period for which you had fully paid and will not be charged again.
						</p>
					</div>
				</Col>
				</Row>
			<ToastContainer/>
			<Modal
				show={show}
				onHide={handleClose}
				backdrop="static"
				keyboard={false}
			>
				<Modal.Body>
					<div className="text-center">
						<p>Processing Payment...</p>
						<div className="spinner-border" role="status">
							<span className="visually-hidden">Loading...</span>
						</div>
					</div>
				</Modal.Body>
			</Modal>
		</Container>)
}

export default Premier;
