import Dropzone from "react-dropzone-uploader-with-credentials";
import '../../assets/css/upload.css';
import API from "../../api";
import {useAuth} from "../../AuthUser";
import {toast} from "react-toastify";
import {useNavigate} from "react-router-dom";
import {Col, Row, Container} from "react-bootstrap";

const notify = (message) =>
	toast.error(message, {
		position: toast.POSITION.TOP_RIGHT,
	});

const Layout = ({ input, previews, submitButton, dropzoneProps, files, extra: { maxFiles } }) => {
	return (
		<div>
			{previews}

			<div {...dropzoneProps}>
				{files.length < maxFiles && input}
				<textarea name="notes"/>

				{files.length > 0 && submitButton}
			</div>
		</div>
	)
}
const Input = ({ accept, onFiles, files, getFilesFromEvent }) => {
	const text = files.length > 0 ? 'Add more files' : 'Choose files'

	return (
			<div className="input-group">
				<input type="file" className="form-control" id="inputGroupFile04" aria-describedby="inputGroupFileAddon04"
							 aria-label="Upload"
							 style={{ display: 'none' }}
							 accept={accept}
							 multiple
							 onChange={e => {
								 getFilesFromEvent(e).then(chosenFiles => {
									 onFiles(chosenFiles)
								 })
							 }}>
					<button className="btn btn-outline-secondary" type="button" id="inputGroupFileAddon04">{text}</button>
				</input>
			</div>
	)
}

const NoDropzoneLayout = ({ previews, submitButton, input, files, dropzoneProps, extra: { maxFiles } }) => {

	const { ref, className, style } = dropzoneProps
	return (
		<>
			<Container>

			<textarea className="form-control mb-4" name="instructor_notes" id="instructor_notes" rows={3} placeholder="Notes for the user"/>

			<div ref={ref} className={className} style={style}>
			{previews}

			{files.length < maxFiles && input}

			{files.length > 0 && submitButton}
		</div>
			</Container>
			</>
	)
}

const VideoUpload = ( { redirectTo, consult = 0, lessonId }) => {
	const { setUser } = useAuth();
	const navigate = useNavigate();
	const getUploadParams = async ({ meta: { name, type } }) => {
		const response = await API.post("admin/lessons/upload-videos", { name, type });
		console.log(response.data)
		return { fields: response.data.fields, meta: { fileUrl: response.data.fileUrl }, url: response.data.uploadUrl }
	}

	const handleChangeStatus = ({ meta }, status) => {
		console.log(status, meta)
	}

	const handleSubmit = async (files, allFiles) => {
		const elem = document.getElementById('instructor_notes');
		console.log("notes ", elem.value)
		try {
			await API.post("admin/lessons/", { instructor_notes: elem.value, lessonId, files});

			if (redirectTo) {
				allFiles.forEach(f => f.remove())
				elem.value = '';
				return navigate(redirectTo, { replace: true });
			}
			return navigate("/dna-profile", { replace: true });


		} catch (e) {
			console.log(e)
			elem.value = '';
			allFiles.forEach(f => f.remove())
			notify(e.response.data.message);
		}

	}

	return (
		<Dropzone
			getUploadParams={getUploadParams}
			onChangeStatus={handleChangeStatus}
			LayoutComponent={NoDropzoneLayout}
			onSubmit={handleSubmit}
			//inputComponent={Input}
			inputContent="Click here to upload lesson video"
			//styles={{ dropzone: { minHeight: 200, maxHeight: 250 } }}
			accept="video/*"
			autoUpload={true}
			maxFiles={1}

		/>
	)
}

export default VideoUpload;
