import * as React from "react";
import {Link, Navigate} from "react-router-dom";
import { useAuth } from "./AuthUser";

import "./assets/css/Home.css"
import {Container, Row, Col, ListGroup, Badge} from "react-bootstrap";
import Button from "react-bootstrap/Button";
import {Helmet} from "react-helmet";
import ReactPlayer from "react-player/lazy";
import Pricing from "./Pricing";
import HomeCarousel from "./components/HomeCarousel";
import Signup from "./components/Signup";
function Home() {
	let auth = useAuth();

	if (auth.user) {
		return <Navigate to="/videos" replace />;
	}

	return (
		<>
			<Helmet>
				<title>Serious Golfers. Serious Instruction. | MyGolfDNA.com</title>
				<meta name="description" content="Serious Golfers. Serious Instruction."/>
			</Helmet>
			<div className="position-relative overflow-hidden home-bg">
				<Container>
					<Row>
				<div className="col-md-7 p-4 p-lg-5 my-0 my-md-5">
					<h1 className="home-heading lh-2 mb-4 text-white">YOUR GAME. <br/>YOUR SWING. <br/><span className="text-secondary">YOUR DNA.</span></h1>
					<p className="lead fw-normal text-white">Where Serious Golfers Get Serious Instruction.
					</p>
					<ul className="list-unstyled text-white-50 mb-5">
						<li><i className="bi bi-check-circle text-success"/> A student-focused system to get better at golf</li>
						<li><i className="bi bi-check-circle text-success"/> Round the clock feedback for your entire game</li>
						<li><i className="bi bi-check-circle text-success"/> 3 Step full game improvement system</li>
					</ul>
					<Button variant="secondary" size="lg" href="/pricing" className="text-white btn-secondary font-weight-bold visually-hidden">GET ACCESS NOW</Button>
				</div>

					<div className="col p-0 p-lg-4 mb-4">
						<Signup/>
					</div>
					</Row>
				</Container>
			</div>
			<div  className="p-4" style={{backgroundColor: "#f8f9faa1"}}>
			<Container>
				<Row className="align-items-center">
				<Col md={12} sm={12} lg={7}>
					<div className="" id="how-it-works">
					<h3><span className="badge dark-bg mb-4 text-white p-3">How It Works</span></h3>

					<p className="lead fw-normal mb-4">You can and will get better at golf <b className="text-secondary">IF</b> you have the correct plan in place.</p>
					<div className="d-flex flex-row mb-4">

					<div className="d-inline-block px-2">
						<i className="bi bi-1-circle-fill text-secondary h1"/>
					</div>
					<div className="d-inline-block align-top px-2">
						<h3 className="monument-font">IDENTIFY YOUR GOLF DNA</h3>
						<p>Improvement starts with the identifying the entire state of your game with your instructor. Establishing your profile helps us prioritize your focus.</p>
					</div>
					</div>
					<div className="d-flex flex-row mb-4">

					<div className="d-inline-block px-2">
						<i className="bi bi-2-circle-fill text-secondary h1"/>
					</div>
					<div className="d-inline-block align-top px-2">
						<h3 className="monument-font">BUILD YOUR PLAN</h3>
						<p>Your instructor will help you set realistic goals and create a plan that fits your lifestyle. We will constantly fine tune your plan until it is dialed in perfectly.</p>
					</div>
					</div>
					<div className="d-flex flex-row">

					<div className="d-inline-block px-2">
						<i className="bi bi-3-circle-fill text-secondary h1"/>
					</div>
					<div className="d-inline-block align-top px-2">
						<h3 className="monument-font">GET TO WORK</h3>
						<p>You will have endless support on your adventure. We are pretty crazy about seeing you succeed, you'll see.</p>
					</div>
					</div>
				</div>
				</Col>
				<Col lg={5} md={12} sm={12}>
					<div className="" style={{padding:'56.25% 0 0 0',position:'relative'}}>
						<iframe
	src="https://player.vimeo.com/video/826399192?h=521d44fbfb&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
	frameBorder="0" allow="autoplay; fullscreen; picture-in-picture" allowFullScreen
	style={{position:'absolute',top:0,left:0,width:'100%',height:'100%'}} title="How it works"/>
					</div>
				</Col>
			</Row>
			</Container>
			</div>
			<div className="position-relative overflow-hidden p-4 teacher-bg" id="your-teacher">
				<Container>
					<Row>
					<Col md={12} sm={12} lg={7}>
						<h3><span className="badge grey-bg text-white mb-4 p-3">Your Teacher</span></h3>
						<p className="lead fw-normal text-white">
						Chris Tyler has been teaching golfers all over the world both in person and on the web for 20+ years. He’s known by many in the golf industry as having one of the sharpest eyes when it comes to the golf swing. Since the early 2000’s, Chris has completed more than 100,000+ virtual swing lessons and has immersed more than 10,000+ hours of private instruction with golfers of all skill sets.
						</p>
							<p className="lead fw-normal text-white">
						As a passionate, self-taught player himself, Chris spent thousands of hours, not just studying the golf swing and how the body moves, but also studying biomechanics and neuromuscular reeducation, which is critical to understand when it comes to helping golfers make changes.
							</p>
								<p className="lead fw-normal text-white">
						In his experience, Chris learned one of the most valuable lessons when it comes to getting you REAL on course results...Every single person that plays the game of golf is vastly different. Every person has their own golf DNA. Every person can and will get better, if they have a plan that is specific to their own DNA.
								</p>
								</Col>
					</Row>
				</Container>
			</div>
			<div className="p-4" id="testimonials">
				<Container>
					<Row>
						<Col>
							<h3><span className="badge dark-bg mb-4 text-white p-3">Testimonials</span></h3>
							<HomeCarousel />
						</Col>
					</Row>
				</Container>
			</div>
			<div className="p-4 mb-4">
				<Container>
					<Row>
						<Col>
				<div className="text-center">
					<Button href="/pricing" variant="secondary" size="lg" style={{fontSize: 30, color: "#ffffff", width: 300}} className="">Get Access Now</Button>
				</div>
						</Col>
					</Row>
				</Container>
			</div>

		</>
	);
}

export default Home;
