import {useState, useEffect} from "react";
import {useAuth} from "../../AuthUser";
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Container from 'react-bootstrap/Container';
import {Helmet} from "react-helmet";
import API from "../../api";
import {SwiperVideos} from "../../components/SwiperVideos";
import NewReleases from "../../components/NewReleases";
import Spinner from "react-bootstrap/Spinner";

function List() {
	let auth = useAuth();

	const [videos, setVideos] = useState([])
	const [isLoading, setLoading] = useState(false)

	useEffect( () => {
		setLoading(true);
		API.get(`video`)
			.then(data => setVideos(data.data))
			.finally(() => setLoading(false));
	}, []);

	return (
		<>
			<Helmet>
				<title>Videos | MyGolfDNA.com</title>
				<meta name="description" content="Videos listing"/>
			</Helmet>
			{isLoading ?
				<div className="p-4" style={{textAlign: 'center'}}> <Spinner className="text-orange" animation="border" /></div> : <div className="">
					<Container>
						<Row>
							<Col>
								<div className="my-4">
									<h4 style={{letterSpacing: "1px"}} className="monument-font text-primary text-uppercase">New Releases</h4>
									<p className="text-black-50">Recently released videos</p>
									<NewReleases/>
								</div>
								<hr/>
							</Col>
						</Row>

						{videos?.map(video => (
							<Row key={video.id}>
								<Col>
									<div className="my-2">
										<h4 style={{letterSpacing: "1px"}} className="monument-font text-primary text-uppercase">{video.title}</h4>
										<p className="text-black-50">{video.description}</p>
										<SwiperVideos
											videos={video.videos}
										/>
									</div>
									<hr/>
								</Col>
							</Row>
						))}
					</Container>
				</div>
			}
		</>
	);
}

export default List;
