import React from "react";
import {
	Button,
	FormGroup,
	Form,
	Row,
	Col,
	Card,
	Container,
} from "react-bootstrap";
import { Formik } from "formik";
import { ToastContainer, toast } from "react-toastify";
import moment from "moment";
import { useAuth } from "../../AuthUser";
import API from "../../api";
import {Helmet} from "react-helmet";
import VideoUpload from "../VideoUpload";
import {useNavigate} from "react-router-dom";

const Consult = () => {
	const { setUser, user } = useAuth();
	const navigate = useNavigate();

	const notify = (message) =>
		toast.error(message, {
			position: toast.POSITION.TOP_RIGHT,
		});

	function isValidDate(s) {
		// Assumes s is "mm/dd/yyyy"
		return moment(s, 'YYYY-MM-DD',true).isValid()

	}


	return (
		<>
			<Helmet>
				<title>Thankyou! | MyGolfDNA.com</title>
				<meta name="description" content="build your dna profile"/>
			</Helmet>
			<div className="mt-4">
				<Container>

					<Row>
						<div className="text-center h1 text-uppercase monument-font">Thank you!</div>
						<Row className="justify-content-center">
							<Col lg={5} md={12} sm={12}>
								<div className="" style={{padding:'56.25% 0 0 0',position:'relative'}}>
									<iframe
										src="https://player.vimeo.com/video/826532488?h=51c8710145&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
										frameBorder="0" allow="autoplay; fullscreen; picture-in-picture" allowFullScreen
										style={{position:'absolute',top:0,left:0,width:'100%',height:'100%'}}
										title="Welcome New Member - MyGolfDNA"/>
								</div>
							</Col>
						</Row>
						<Col sm={12} md={{ span: 8, offset: 2 }} lg={{ span: 6, offset: 3 }} xl={{ span: 6, offset: 3 }}>
							<div className="mt-3 d-grid gap-3 text-center">
								<p className="text-center">
									You have successfully subscribed to MyGolfDNA.com!
								</p>
								<p className="text-center">
									Let's get started setting up your DNA Profile. You <b>MUST</b> complete the DNA wizard before accessing the website.
								</p>
								<div className="text-center">
									<Button variant="secondary" size="lg" href="wizard/shipping" className="text-white font-weight-bold">Get Started</Button>
								</div>
							</div>


						</Col>
					</Row>
				</Container>
			</div>
		</>
	);
};

export default Consult;
