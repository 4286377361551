import * as React from "react";
import {useAuth} from "../AuthUser";
import {Link, Navigate, useNavigate} from "react-router-dom";
import Col from 'react-bootstrap/Col';
import Nav from 'react-bootstrap/Nav';
import Row from 'react-bootstrap/Row';
import Badge from 'react-bootstrap/Badge';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Container from 'react-bootstrap/Container';
import {Helmet} from "react-helmet";
import Button from "react-bootstrap/Button";
import VideoUpload from "./VideoUpload";

function UploadVideos() {
	let auth = useAuth();
	const navigate = useNavigate();
	const firstName = !auth.user.firstName ? '' : auth.user.firstName;
	const lastName = !auth.user.lastName ? '' : auth.user.lastName;
	const fullName = `${firstName} ${lastName}`;

	console.log('auth: ', auth);
	if(!auth.user) {
		return <Navigate to="/signin" replace/>
	}

	return (
		<>
			<Helmet>
				<title>Upload Lesson Videos | mygolfdna.com</title>
				<meta name="description" content="upload lesson videos"/>
			</Helmet>
			<div className="mt-5">
				<Container>
					<Row>
						<Col sm={12} md={{ span: 6, offset: 3 }} lg={{ span: 6, offset: 3 }} xl={{ span: 8, offset: 2 }}>
							<div className="hstack gap-3">
								<div className="h3">Upload Lesson Videos</div>
							</div>
							<hr/>
							<VideoUpload
							redirectTo="/lessons"/>
						</Col>
					</Row>
				</Container>
			</div>
		</>
	);
}

export default UploadVideos;
