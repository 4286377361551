import {useAuth} from "./AuthUser";
import {Link, Navigate, useNavigate} from "react-router-dom";
import Col from 'react-bootstrap/Col';
import Nav from 'react-bootstrap/Nav';
import Row from 'react-bootstrap/Row';
import Badge from 'react-bootstrap/Badge';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Container from 'react-bootstrap/Container';
import {Helmet} from "react-helmet";
import Details from "./pages/Account/Details";
import Security from "./pages/Account/Security";
import VideoInput from "./pages/VideoUpload";
import Button from "react-bootstrap/Button";
import API from "./api";
import {useEffect, useState} from "react";
import {Alert} from "react-bootstrap";
import Cookies from "js-cookie";
import moment from "moment";
import Spinner from 'react-bootstrap/Spinner';
import { Chart } from 'primereact/chart';

function SubscribeButton() {
	return (
		<a className="text-danger" href="/pricing">Get Access Now!</a>
	)
}

function DNAProfile() {
	// eslint-disable-next-line no-restricted-globals

	let auth = useAuth();
	const [chartData, setChartData] = useState({});
	const [chartOptions, setChartOptions] = useState({});

	useEffect(() => {
		const documentStyle = getComputedStyle(document.documentElement);
		const textColor = documentStyle.getPropertyValue('--text-color');
		const textColorSecondary = documentStyle.getPropertyValue('--text-color-secondary');
		const surfaceBorder = documentStyle.getPropertyValue('--surface-border');
		const data = {
			labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
			datasets: [
				{
					label: 'Handicap',
					fill: false,
					borderColor: documentStyle.getPropertyValue('--orange-500'),
					yAxisID: 'y',
					tension: 0.4,
					data: [65, 59, 80, 81, 56, 55, 10]
				}
			]
		};
		const options = {
			stacked: false,
			maintainAspectRatio: false,
			aspectRatio: 0.6,
			plugins: {
				legend: {
					labels: {
						color: textColor
					}
				}
			},
		};

		setChartData(data);
		setChartOptions(options);
	}, []);

	return (
		<>
		<Helmet>
			<title>DNA Profile | MyGolfDNA.com</title>
			<meta name="description" content="Your DNA Profile"/>
		</Helmet>
		<div className="mt-4">
			<Container>
				<Row>
					<Col>

					</Col>
					<Col sm={12}  md={6} lg={6}>
						<div className="card" style={{maxHeight: "300px"}}>
							<Chart type="line" style={{maxHeight: "300px"}} data={chartData} options={chartOptions} />
						</div>
					</Col>
				</Row>
			</Container>
		</div>
			</>
	);
}

export default DNAProfile;
