import {useEffect, useState} from "react";
import {useAuth} from "../../AuthUser";
import {Navigate, useLocation, useNavigate, useParams} from "react-router-dom";
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Container from 'react-bootstrap/Container';
import VideoUpload from "./VideoUpload";
import API from "../../api";
import Spinner from "react-bootstrap/Spinner";
import Button from "react-bootstrap/Button";


function UploadVideos() {
	let auth = useAuth();
	let navigate = useNavigate();
	let { lessonId } = useParams();

	const [lesson, setLesson] = useState([])
	const [isLoading, setLoading] = useState(true)

	const fetchData = async () => {
		const response = await API.get(`admin/lessons/${lessonId}`);
		const data = response.data;
		console.log(response);
		setLesson(data);
	}

	useEffect(() => {
		setLoading(true);
		fetchData().then().finally(() => setLoading(false))
	}, [])

	const deleteLesson = async (lessonId) => {
		await API.delete(`admin/lessons/${lessonId}`);
		navigate('/admin/lessons');
	}

	if(!auth.user) {
		return <Navigate to="/signin" replace/>
	}
	if (!auth.user.admin) {
		return <Navigate to="/" replace />;
	}

	return (
		isLoading ? <div style={{textAlign: 'center'}}> <Spinner animation="border" /></div> :
			<>
			<div className="mt-5">
				<Container>
					<Row>
						<Col>
							<div className="hstack gap-3">
								<div className="h3">Instructor Lesson</div>
								<Button onClick={() => deleteLesson(lesson.lesson.id)} variant="danger"><i className="bi bi-trash"/></Button>
							</div>
							<hr/>
							<ul className="list-unstyled">
								<li>Name: {lesson.account.firstName} {lesson.account.lastName}</li>
								<li>Handicap: {lesson.wizard.handicap}</li>
								<li>Consult: {lesson.lesson.consult ? 'Yes' : 'No'}</li>
								<li>Videos:
									<ul>
										{lesson.lesson.reviewVideos.map((video,index) => {
											if (video.type === 'user') {
												return <li key={index}><a key={index} href={video.filename}> Video {index+1}</a></li>
											}
											return '';
										})}
									</ul>
								</li>
									<li>Notes: {lesson.lesson.notes}</li>
								</ul>
							<ul className="list-unstyled">
									<li>Bunker Play: {lesson.wizard.bunkerPlay}</li>
									<li>Chipping: {lesson.wizard.chipping}</li>
									<li>Course Management: {lesson.wizard.courseManagement}</li>
									<li>Driver Accuracy: {lesson.wizard.driverAccuracy}</li>
									<li>Driver Distance: {lesson.wizard.driverDistance}</li>
									<li>Equipment: {lesson.wizard.equipment}</li>
									<li>Fairways, Woods and Hybrids: {lesson.wizard.fairwaysWoodsHybrids}</li>
									<li>Frustrations: {lesson.wizard.frustrates}</li>
									<li>Goals: {lesson.wizard.goals}</li>
									<li>Injuries: {lesson.wizard.injuries}</li>
									<li>Iron Play: {lesson.wizard.ironPlay}</li>
									<li>Pitching: {lesson.wizard.pitching}</li>
									<li>Years Played: {lesson.wizard.practice}</li>
									<li>Putting: {lesson.wizard.putting}</li>
									<li>Rounds Played: {lesson.wizard.rounds}</li>
									<li>Training Aids Used: {lesson.wizard.training}</li>

							</ul>
							<VideoUpload
								redirectTo="/admin/lessons"
								lessonId={lesson.lesson.id}
							/>
						</Col>
					</Row>
				</Container>
			</div>
		</>
	);
}

export default UploadVideos;
